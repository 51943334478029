<script>
    import Label from "./Label.svelte";
    import {clickOutside} from "../utils/clickOutside.js";

    export let config = null
    export let value = config?.default
    export let groupValue = null

    let placeholder = config?.placeholder
    let required = config?.required
    let widgetVisibility = false

    const select = (val) => {
        value = val
        widgetVisibility = false
    }

    const show = () => {
        widgetVisibility = true
    }

    const hide = () => {
        widgetVisibility = false
    }

    $: widgetOptions = config?.helper[groupValue]

    $: if (!value) {
        hide()
    }
</script>

{#if config !== null}
    <div class="widget widget-text kwh-widget group_{groupValue}" use:clickOutside on:click_outside={hide}>
        <Label
                label="{config?.label}"
                control="kwh"
                required />

        <input
            type="text"
            name="kwh"
            id="kwh"
            required="{required}"
            autocomplete="off"
            bind:value={value}
            on:focus={show}
            placeholder="{placeholder}" />

        {#if widgetOptions?.length && widgetVisibility}
            <ul class="autocomplete-items-list" hidden="{!widgetVisibility}">
                {#each widgetOptions as record, i}
                    <li>
                        <button type="button" on:click={select(record.key)}>
                            <span>{record.value}</span>
                        </button>
                    </li>
                {/each}
            </ul>
        {/if}
    </div>
{/if}
