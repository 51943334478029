<script>
    import Legend from "./Legend.svelte";
    import Label from "./Label.svelte";
    import Explanation from "./Explanation.svelte";

    export let config = null

    let options = config.options
    let value = parseInt(config.default)
    let behavior = config.behavior
    let required = config?.required
    let checked = config?.checked
    let name = config?.name
    let type = config?.inputType
</script>

{#if behavior === 'user'}

    {#if type === 'radio'}
        <div class="widget {name}-widget widget-radio">
            <fieldset class="radio_container">
                <Legend
                        label="{config?.label}"
                        explanation="{config?.explanation}"
                        required />

                {#each options as option, i }
                    <span>
                        <input
                            type="radio"
                            name="{name}"
                            id="{name}_{i}"
                            value="{option.value}"
                            required="{required}"
                            bind:group={value} />

                        <Label label="{option.label}" control="{`${name}_${i}`}"/>

                        <Explanation explanation="{option.explanation}"/>
                    </span>
                {/each}
            </fieldset>
        </div>
    {/if}

    {#if type === 'checkbox'}
        <div class="widget {name}-widget widget-checkbox">
            <fieldset class="checkbox_container">
                <span>
                    <input
                            type="checkbox"
                            name="{name}"
                            id="{name}"
                            value="{config.value}"
                            required="{required}"
                            checked="{checked ?? false}"
                    />

                    <Label label="{config.label}" control="{name}"/>

                    <Explanation explanation="{config.explanation}"/>
                </span>
            </fieldset>
        </div>
    {/if}
{:else}
    <input
        type="hidden"
        name="{name}"
        bind:value={value} />
{/if}
