<script>
    export let label = '';
    export let id = '';
    export let control = '';
    export let required = false;
</script>

{#if label}
    <label id="{id}" for="{control}">
        {label}
        {#if required}
            <span class="mandatory">*</span>
        {/if}
    </label>
{/if}
