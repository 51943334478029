<script>
    import GroupRadioWidget from "./GroupRadioWidget.svelte";
    import PostalWidget from "./PostalWidget.svelte";
    import DynamicSelectWidget from "./DynamicSelectWidget.svelte";
    import TypeRadioWidget from "./TypeRadioWidget.svelte";
    import KwhWidget from "./KwhWidget.svelte";
    import DynamicWidget from "./DynamicWidget.svelte";

    const config = $$props.config;
    let formAction = config.form.action

    let cityOptions
    let districtOptions

    let cityWidget
    let districtWidget

    let groupValue = config.fields?.group?.default
    let postalValue = config.fields?.postal?.default
    let cityValue = config.fields?.city?.default
    let districtValue = config.fields?.district?.default
    let kwhValue = config.fields?.kwh?.default
    let typeValue = config.fields?.type?.default

    $: dynamicFields = config.fields?.dynamic[groupValue]

    /**
     * Handle form action
     */
    $: if(groupValue || typeValue)
    {
        if(config.form.action || !config.fields?.group?.options?.length || !typeValue)
        {
            formAction = config.form.action
        }
        else
        {
            let group = config.fields.group.options.find((opt) => opt.value === groupValue)

            switch (typeValue)
            {
                case 'p':
                    formAction = group.privateAction
                    break

                case 'g':
                    formAction = group.businessAction
                    break
            }
        }
    }

    /**
     * Handle postal changes, to show valid cities
     */
    $: if(postalValue || 1) {

        if(config.fields?.postal?.event)
        {
            switch (config.fields.postal.event.field)
            {
                case "city":

                    if(postalValue?.length >= (config.fields?.postal?.event?.min ?? 2))
                    {
                        let route = config.fields.postal.event.route.replace('__POSTAL__', postalValue)

                        cityWidget?.update(route, {group:groupValue})
                    }
                    else
                        cityWidget?.clear()

                    break
            }
        }
    }

    /**
     * Handle city changes, to show valid districts
     */
    $: if(cityValue || 1) {

        if(config.fields?.city?.event)
        {
            switch (config.fields.city.event.field)
            {
                case "district":

                    if(cityValue)
                    {
                        let route = config.fields.city.event.route.replace('__CITY__', cityValue)
                                                                  .replace('__POSTAL__', postalValue)

                        districtWidget?.update(route, {group:groupValue})
                    }
                    else
                        districtWidget?.clear()

                    break
            }
        }
    }
</script>

<form
    action="{formAction}"
    method="{config.form.method}">

    <input type="hidden" name="REQUEST_TOKEN" value="{config.form.requestToken}"/>

    <GroupRadioWidget
            config="{config.fields?.group}"
            options="{config.fields.group.options}"
            bind:value={groupValue} />

    <PostalWidget
            config="{config.fields?.postal}"
            bind:value={postalValue}
            bind:groupValue={groupValue} />

    <DynamicSelectWidget
            config="{config.fields?.city}"
            name="city"
            bind:value={cityValue}
            bind:options={cityOptions}
            bind:this={cityWidget} />

    <DynamicSelectWidget
            config="{config.fields?.district}"
            name="district"
            bind:value={districtValue}
            bind:options={districtOptions}
            bind:this={districtWidget} />

    <KwhWidget
            config="{config.fields?.kwh}"
            {groupValue}
            bind:value={kwhValue} />

    <TypeRadioWidget
            config="{config.fields?.type}"
            bind:value={typeValue} />

    {#if dynamicFields?.length}
        {#each dynamicFields as widget, i }
            <DynamicWidget config="{widget}"/>
        {/each}
    {/if}

    <div class="widget widget-submit">
        <input type="submit" class="submit" value="{config.translations.form.submit}">
    </div>

</form>
