<script>
    import Label from "./Label.svelte";
    import Legend from "./Legend.svelte";

    export let config = null
    export let value

    let required = config?.required
</script>

{#if config?.behavior === 'user'}
    <div class="widget type-widget widget-radio">
        <fieldset class="radio_container">
            <Legend
                    label="{config?.label}"
                    required />

            <span>
                <input
                    type="radio"
                    name="type"
                    id="type_p"
                    value="p"
                    required="{required}"
                    bind:group={value} />

                <Label label="Privatkunde" control="{`type_p`}"/>
            </span>
            <span>
                <input
                    type="radio"
                    name="type"
                    id="type_g"
                    value="g"
                    required="{required}"
                    bind:group={value} />

                <Label label="Geschäftskunde" control="{`type_g`}"/>
            </span>
        </fieldset>
    </div>
{:else if config?.behavior === 'hidden'}
    <input
            type="hidden"
            name="type"
            bind:value={value} />
{/if}
