<script>
    import Explanation from "./Explanation.svelte";

    export let label = '';
    export let required = false;
    export let explanation;
</script>

{#if label}
    <legend>
        {label}
        {#if required}
            <span class="mandatory">*</span>
        {/if}
        <Explanation explanation="{explanation}"/>
    </legend>
{/if}
