<script>
    import Label from "./Label.svelte";
    import Loader from "./Loader.svelte";

    export let config = null
    export let options = []
    export let value = config?.default
    export let name

    let required = config?.required
    let placeholder = config?.placeholder
    let showLoader = false

    $: disabled = !options.length

    export const update = async (route, parameters = {}) => {
        showLoader = true

        const res = await fetch(route, {
            method: 'POST',
            body:   JSON.stringify(parameters)
        });

        options = await res.json();
        showLoader = false
    }

    export const clear =() => {
        value = ''
        options = []
    }
</script>

{#if config !== null}
    <div class="widget widget-select select widget-{name}">
        <Label
                label="{config?.label}"
                control="{name}"
                required />

        <select
                disabled="{disabled}"
                name="{name}"
                id="{name}"
                required="{required}"
                bind:value={value} >

            <option selected disabled value="">{placeholder}</option>

            {#each options as option}
                <option value={option.value}>{option.label}</option>
            {/each}
        </select>

        <Loader show="{showLoader}" />
    </div>
{/if}
