<script>
    import Label from "./Label.svelte";
    import Loader from "./Loader.svelte";
    import {clickOutside} from "../utils/clickOutside.js";

    export let config = null
    export let value = config?.default

    export let groupValue = '';

    let placeholder = config?.placeholder
    let required = config?.required
    let min = config?.min ?? 5

    let filteredResults = []
    let listVisibility = false
    let showLoader = false

    const autocomplete = async () => {
        if(!config?.autocomplete || !value.trim() || value.length < (config?.autocomplete.min ?? 3))
        {
            return
        }

        showLoader = true

        let route = config.autocomplete.route.replace('__POSTAL__', value)

        const res = await fetch(route, {
            method: 'POST',
            body:   JSON.stringify({group:groupValue})
        });

        filteredResults = await res.json();
        showLoader = false
        listVisibility = true
    }

    const select = (val) => {
        value = val
        listVisibility = false
    }

    const show = () => {
        listVisibility = true
    }

    const hide = () => {
        listVisibility = false
    }

    $: if (!value) {
        filteredResults = []
        hide()
    }

</script>

{#if config !== null}
    <div class="widget widget-text postal-widget" use:clickOutside on:click_outside={hide}>
        <Label
                label="{config?.label}"
                control="postal"
                required />

        <input
            type="text"
            name="postal"
            id="postal"
            autocomplete="off"
            required="{required}"
            placeholder="{placeholder}"
            minlength="{min}"
            bind:value={value}
            on:focus={show}
            on:input={autocomplete} />

        <Loader show="{showLoader}" />

        {#if filteredResults.length > 0 || !listVisibility}
            <ul class="autocomplete-items-list" hidden="{!listVisibility}">
                {#each filteredResults as record, i}
                    <li>
                        <button type="button" on:click={select(record.value)}>
                            <span class="postal">{record.label}</span>
                        </button>
                    </li>
                {/each}
            </ul>
        {/if}
    </div>
{/if}
