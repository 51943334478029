<script>
    import Legend from "./Legend.svelte";
    import Label from "./Label.svelte";

    export let config = null
    export let options
    export let value

    let required = config?.required
    let isSelectable = options.length > 1
</script>

{#if isSelectable}
    <div class="widget group-widget widget-radio">
        <fieldset class="radio_container">
            <Legend
                    label="{config?.label}"
                    required />

            {#each options as option, i }
                <span>
                    <input
                        type="radio"
                        name="group"
                        id="group_{i}"
                        value="{option.value}"
                        required="{required}"
                        bind:group={value} />

                    <Label label="{option.label}" control="{`group_${i}`}"/>
                </span>
            {/each}

        </fieldset>
    </div>
{:else}
    <input
        type="hidden"
        name="group"
        bind:value={value} />
{/if}
