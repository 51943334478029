<script>
    export let show = false
</script>

<span class="loader" hidden="{!show}">
    <span class="spinner"></span>
</span>

<style>
    .spinner {
        width: 18px;
        height: 18px;
        border: 1px solid #000;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation .5s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
