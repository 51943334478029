import TariffFinder from "./components/TariffFinder.svelte";

const div = document.createElement("div");
const script = document.currentScript;
      script.parentNode.insertBefore(div, script);

const app = new TariffFinder({
    target: div,
    props: {
        config: traiffFinderConfig
    }
});
